:root {
    --fontSize: 1.2rem;
}

/*
* TextField border-color when focused.
* https://material-ui.com/components/text-fields/#color
*/
:root .MuiOutlinedInput-root:focus-within fieldset {
    border-color: var(--darkPurple);
}

.container {
    display: grid;
    row-gap: 3rem;
    max-width: 94rem;
    margin: 1rem auto;
    text-align: left;
    padding: 1rem;
}

.form {
    display: grid;
    row-gap: 3rem;
}

.section {
    display: flex;
    flex-direction: column;
}

.subtitle {
    font-size: calc(var(--fontSize) + 0.8rem);
    font-weight: bold;
    margin-bottom: 1rem;
}

.featured_image {
    height: 250px;
    border-radius: 10px;
    position: relative;
}

.section_category {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.textfield {
    width: 100%;
    flex-wrap: wrap;
}

.category_tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.remove_button_wrap {
    height: 2rem;
    position: relative;
    margin: 1rem;
}

.remove_button {
    height: 1rem;
    width: 1rem;
    padding: 0;
    z-index: 2;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: white;
}

.remove_button:hover {
    background-color: white;
}

.remove_image_icon {
    fill: red;
    font-size: 3rem;
    position: relative;
    top: -5px;
}

.remove_category_icon {
    fill: red;
    font-size: 2rem;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}

.spacer {
    flex-grow: 1;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.modalInnerContainer {
    background-color: white;
    border: 1px solid #000;
    border-radius: 0.8rem;
    padding: 1.5rem;
    width: 35rem;
    font-size: 1rem;
}

.modalTitle {
    margin-bottom: 4rem;
}

.modalButton {
    display: inline;
    margin-right: 1rem;
    float: left;
}

@media only screen and (min-width: 600px) {
    .title {
        font-size: calc(var(--fontSize) + 1rem);
    }

    .section_category {
        flex-direction: row;
    }

    .textfield {
        width: 40em;
    }

    .modal {
        width: 80rem;
    }

    .modalInnerContainer {
        padding: 3rem;
    }
}

@media only screen and (min-width: 760px) {}
