:root {
    --fontSize: 1.4rem;
}

.wrap {
    padding: 0;
    border: none;
    cursor: pointer;
}

.wrap:focus {
    outline-color: var(--salmon);
}

.card {
    min-width: 16rem;
    height: 20rem;
}

.media {
    height: 80%;
    background-color: var(--lightGrey);
}

.content {
    padding: 0 !important;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    font-size: calc(var(--fontSize));
}
