.card {
    padding: 2rem;
    border-radius: 10px;
    background-color: white;
    height: 35rem;
}

.card_content {
    display: flex;
    column-gap: 2rem;
    width: 100%;
    height: 100%;
}

.panel_left {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 2rem;
    flex-wrap: nowrap;
}

.panel_right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.featured_image_container {
    height: 70%;
    border-radius: 10px 10px 0 0;
    background-color: var(--darkPurple);
}

.section_author {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 30%;
    border-radius: 0 0 10px 10px;
    background-color: var(--salmon);
}

.author_avatar {
    height: 5rem;
    width: 5rem;
}

.author_name {
    color: white;
    font-size: calc(var(--fontSize) * 1.2);
}

.author_inner_content {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 0.5rem;
}

.author_inner_content img {
    height: 2rem;
    width: 2rem;
}

.card_link {
    text-decoration: none;
    color: inherit;
}

.project_name_like_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.description {
    margin-top: 3rem;
    font-size: var(--fontSize);
}

.stats {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding: 2rem 0;
}

.stat_type {
    font-size: var(--fontSize);
}

.stat_value {
    font-size: calc(var(--fontSize) * 2.5);
    font-weight: bold;
    color: var(--darkPurple);
}

@media only screen and (max-width: 600px) {
    .card {
        height: fit-content;
    }

    .card_content {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 100%;
    }

    .panel_left {
        width: 100%;
        height: 18rem;
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        flex-wrap: nowrap;
    }

    .panel_right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .featured_image_container {
        height: 100%;
        width: 60%;
        border-radius: 10px 0 0 10px;
    }

    .section_author {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        height: 100%;
        width: 40%;
        border-radius: 0 10px 10px 0;
    }

    .stat_type {
        font-size: calc(var(--fontSize) * 0.8);
    }
}
