.root {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
}

.projectsColumn {
    margin: 3rem auto;
    width: 90%;
}

.projectsHeader {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.projectHeader {
    justify-self: start;
    display: block;
    /*TODO - Change bottom padding to zero when adding search bar back*/
    margin-bottom: 2rem;
    border-bottom-color: var(--lightPurple);
    border-bottom-width: 0.2rem;
    border-bottom-style: solid;
}

.projects {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 1rem;
}

.searchBar {
    display: block;
    width: 40rem;
    margin: 2rem auto 2rem auto;
    font-size: 1.8rem;
    position: relative;
    background-color: white;
    border-radius: 5px;
    text-align: left;
    border: 0.1rem solid black;
}

.searchIcon {
    padding: 0 0 0 1rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    color: #61dafb;
    align-items: center;
    justify-content: center;
}

.avatar {
    margin: 3rem auto 3rem auto;
    width: 10rem;
    height: 10rem;
}

.usernameAndPremium {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.userName {
    font-weight: normal;
    font-size: 2.8rem;
    margin: 1rem auto 1rem auto;
}

.premiumIndicator {
    height: 26px;
}

.userInfo {
    vertical-align: center;
    margin-bottom: 1rem;
}

.icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.icon_star {
    color: #1583d8;
}

.icon_heart {
    color: #ff0000;
}

.iconInfo {
    display: inline;
    position: relative;
    font-weight: normal;
    font-size: 1.5rem;
    bottom: 0.4rem;
}

.newButton {
    border: 0.2rem solid var(--salmon);
    border-radius: 0.8rem;
    color: var(--salmon);
    padding: 1.5rem;
    font-size: 1.2rem;
}

@media only screen and (min-width: 760px) {
    .projectsColumn {
        margin-top: 3rem;
    }

    .projectHeader {
        display: block;
        /*TODO - Change bottom padding to zero when adding search bar back*/
        margin-bottom: 2rem;
    }

    .searchBar {
        display: block;
        width: 40rem;
        margin: 2rem auto 2rem auto;
        font-size: 1.8rem;
        position: relative;
        background-color: white;
        border-radius: 5px;
        text-align: left;
        border: 0.1rem solid black;
    }
}

@media only screen and (min-width: 1200px) {
    .root {
        display: grid;
        margin: auto;
        grid-template-columns: 20% 80%;
        grid-template-rows: repeat(auto-fill, 1fr);
        justify-items: center;
    }

    .alert {
        width: 80%;
        max-width: 85rem;
        font-size: 1.4rem;
        margin: 4rem auto 0 auto;
    }

    .user {
        margin-top: 4rem;
    }

    .projects {
        width: 100%;
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(2, minmax(45rem, 1fr));
        grid-row-gap: 4rem;
    }

    .searchBar {
        display: inline;
        float: right;
        margin: 2rem 0 2rem 0;
    }
}
