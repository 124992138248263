.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile_picture_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_picture {
    height: 10rem;
    width: 10rem;
}
