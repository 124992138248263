.container {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: left;
}

.title {
    font-size: large;
    font-weight: normal;
    margin: auto;
    text-align: center;
}

.title_chiqpack {
    color: var(--salmon);
    font-weight: bold;
}

.tables_row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    row-gap: 2.5rem;
}

.table {
    border-radius: 10px;
    width: 85%;
    margin: auto;
}

.table_title_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: space-between;
    padding: 1.5rem;
    text-align: center;
}

.table_title {
    font-size: medium;
    line-height: 1;
}

.table_subtitle {
    font-size: x-small;
    line-height: 0.75;
}

.table_divider {
    width: 100%;
    border-top: 1px solid black;
    box-sizing: border-box;
}

.table_body {
    padding: 0.8rem;
}

.feature_list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.list_item {
    display: flex;
    align-items: flex-start;
}

.list_item_text {
    font-size: calc(var(--fontSize) + 0.1rem);
}

.list_item_font_color_black {
    color: var(--lightBlack);
}

.list_item_small_text {
    font-size: calc(var(--fontSize) - 0.2rem);
}

@media only screen and (min-width: 760px) {
    .container {
        max-width: 80rem;
        padding: 4rem;
        row-gap: 3rem;
    }

    .title {
        font-size: x-large;
    }

    .tables_row {
        column-gap: 2rem;
        flex-direction: row;
    }

    .table {
        border-radius: 10px;
        width: 100%;
        min-width: 20rem;
    }

    .table_title_container {
        padding: 2rem;
        flex-direction: row;
        align-items: center;
    }

    .table_title {
        font-size: large;
        line-height: 0.75;
    }

    .table_subtitle {
        font-size: small;
    }

    .table_body {
        padding: 1.6rem;
    }

    .feature_list {
        row-gap: 2rem;
    }

    .list_item_text {
        font-size: calc(var(--fontSize) + 0.2rem);
    }

    .list_item_small_text {
        font-size: var(--fontSize);
    }
}

@media only screen and (min-width: 1200px) {
    .tables_row {
        column-gap: 4rem;
    }
}
