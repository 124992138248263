.searchBar {
    display: block;
    width: 40rem;
    margin: 2rem auto 2rem auto;
    font-size: 1.8rem;
    position: relative;
    background-color: white;
    border-radius: 5px;
    text-align: left;
    border: 0.1rem solid black;
}

.searchIcon {
    padding: 0 0 0 1rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    color: #61dafb;
    align-items: center;
    justify-content: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(55rem, 1fr));
    grid-row-gap: 4rem;
    justify-items: center;
    padding-top: 3rem;
    align-items: center;
}

.paginationBar {
    display: block;
    margin: 32rem auto 0 auto;
    width: 100%;
}

@media only screen and (max-width: 1200px) {

    .paginationBar {
        display: block;
        margin: 2rem auto 4rem auto;
        width: 100%;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(1, minmax(50rem, 1fr));
        justify-items: center;
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .grid {
        display: grid;
        margin: auto;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 1rem;
        justify-items: center;
        padding-top: 3rem;
    }

    .searchBar {
        display: block;
        width: 90%;
        margin: 2rem auto 2rem auto;
        font-size: 1.8rem;
        position: relative;
        background-color: white;
        border-radius: 5px;
        text-align: left;
        border: 0.1rem solid black;
    }
}
