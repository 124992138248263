.container {
    width: 100%;
}

.heading_text {
    text-align: right;
}

.cards_image {
    margin-top: 3rem;
    width: 90%;
}

.sliderWrapper {
    margin: 4rem 0;
}

.slideWrapper {
    display: flex !important;
    justify-content: center;
}

.slide {
    transform: scale(0.7);
    transition: transform 1000ms;
    opacity: 0.5;
}
