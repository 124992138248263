.container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.premiumText {
    font-size: 4rem;
}

.logo {
    margin: 1rem auto 0 auto;
    width: 40rem;
}

.alert {
    font-size: 1.38rem;
}

