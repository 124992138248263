
.outer_container {
    width: 90%;
    max-width: 94rem;
    margin: 1rem auto;
    font-size: 1rem;
}

.logo {
    height: 10rem;
}

.logo_header {
    margin: 1rem 0 2.5rem 0;
    font-weight: lighter;
    font-size: 2.5rem;
}

.login_alert {
    font-size: 1.39rem;
    width: 32rem;
    margin: 0 auto 2rem auto;
}

.form_container {
    background-color: white;
    width: 35rem;
    padding: 4rem 2rem;
    border: 1px solid black;
    border-radius: 0.5rem;
}

.login_button {
    background-color: #D0ABE1;
    color: whitesmoke;
    font-size: 1.4rem;
}

.login_button:hover {
    background-color: #D0ABE9;
}

.sub_container {
    margin-top: 2rem;
    width: 35rem;
    padding: 1rem 1rem;
    border: 1px solid black;
    border-radius: 0.5rem;

}

.sub_header {
    font-weight: lighter;
}

.sub_link {
    text-decoration: none;
    color: var(--lightPurple);
    font-weight: bold;
}

@media only screen and (min-width: 760px) {
    .logo_wrapper {
        margin-top: 4rem;
    }

}
