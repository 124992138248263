.panel_container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(55rem, 1fr));
    grid-row-gap: 4rem;
    justify-items: center;
    padding-top: 3rem;
    align-items: center;
}

.title {
    font-size: 2.6rem;
}

.search_container {
    width: 60rem;
    margin: auto;
}

.search_box {
    font-size: 1.8rem;
    position: relative;
    background-color: white;
    border-radius: 5px;
}

.search_icon {
    padding: 0 0 0 1rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    color: grey;
    align-items: center;
    justify-content: center;
}

.input_input {
    padding: 0.19em 0 0 4em;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .grid {
        display: grid;
        width: 80%;
        grid-template-columns: repeat(1, minmax(50rem, 1fr));
        justify-items: center;
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .search_container {
        width: 90%;
    }
    .grid {
        display: grid;
        width: 90%;
        margin: auto;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 1rem;
        justify-items: center;
        padding-top: 3rem;
    }

}
