.container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    max-width: 40rem;
}

.topRow {
    display: flex;
    flex-wrap: nowrap;
}

.middleRow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;
}

.bottomRow {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    column-gap: 1rem;
}

.blurb {
    width: 60%;
    font-size: var(--fontSize);
    line-height: 2rem;
}

.chiqpackText {
    font-weight: bold;
    color: var(--salmon);
}

.heroContainer {
    width: 40%;
    position: relative;
    margin-bottom: 1rem;
}

.hero {
    width: 100%;
    position: absolute;
    top: -5.5rem;
}
