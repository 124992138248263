.container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 1rem;
}

@media only screen and (min-width: 760px) {
    /* .container {
        flex-direction: row;
        min-height: 30rem;
        width: 60rem;
        column-gap: 1rem;
    } */
}