:root {
    --fontSize: 1.2rem;
}

.appbar {
    background-color: var(--lightGrey);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: black;
}

.title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-align: left;
    font-size: 2rem;
    padding: 2rem 0 0.2rem 2rem;
}

.titleProjectName {
    color: var(--lightPurple);
}

.tabs_element {
    height: calc(var(--fontSize) + 5rem);
}

.tabs_scroll_buttons {
    display: flex;
    width: 2rem;
}

.tab_indicator {
    background: var(--lightPurple);
    margin-bottom: 0.5rem;
}

.tab_wrapper {
    display: flex;
    flex-direction: row;
}

.tab_label {
    font-size: 1.2rem;
    text-transform: capitalize;
}

.tab_icon {
    font-size: 1.8rem;
    padding-top: 5px;
    /* TODO: find a better way to account for misalignment */
}

@media only screen and (min-width: 600px) {
    .title {
        font-size: calc(var(--fontSize) + 1rem);
    }

    .tab_label {
        font-size: 1.4rem;
    }

    .tab_icon {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 760px) {
    .tabs_scroll_buttons {
        display: none;
    }
}
