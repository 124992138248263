.container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}

.carousel {
    width: 100%;
}

.bezierContainer {
    display: flex;
    justify-content: center;
    align-self: center;
}

.loadingContainer {
    display: flex !important;
    justify-content: center;
    align-self: center;
    height: 90vh;
    align-items: center;
}

.description {
    font-size: calc(var(--fontSize) * 1.2);
}

@media only screen and (min-width: 760px) {
    .description {
        font-size: calc(var(--fontSize) * 2);
    }
}
