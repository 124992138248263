.container {
    display: flex;
    width: 100%;
    background-color: #eaeaea;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    height: var(--footer-container-height);
}

.logo {
    height: 10rem;
}

.panelsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.panel {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
    "."
    "Links";
    margin: 1.5rem auto 0 auto;
    border-top: 2px solid var(--lightPurple);
    width: 85%;
}

.title {
    font-size: 1.8rem;
    font-weight: bolder;
    text-decoration: underline;
    grid-column: 1;
}

.link {
    font-size: var(--font-size);
    color: var(--darkPurple);
    text-decoration: none;
}

.list {
    width: 100%;
    text-align: center;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem 0;
    grid-template-areas:
    ". ."
    ". .";
    grid-area: Links;
    padding: 0;
    list-style: none;
}


@media (min-width: 767px) {
    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: #eaeaea;
        padding: 1rem 0;
        position: absolute;
        bottom: 0;
        height: var(--footer-container-height);
    }

    .panelsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    .panel {
        display: block;
        border-top: none;
        margin: 0 auto;
        width: auto;
    }

    .logoSection {
        position: relative;
        left: 0;
        height: 10rem;
        padding-left: 2rem;
    }

    .logo {
        height: 10rem;
    }

    .title {
        font-size: 1.8rem;
        font-weight: bolder;
        text-decoration: underline;
    }

    .list {
        width: auto;
        display: block;
        padding: 0;
        list-style: none;
    }

    .link {
        display: block;
        padding: 0.25rem 0 0.25rem 0;
    }
}
