.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    background-color: var(--lightPurple);
    opacity: 0.95;
    position: absolute;
    color: white;
    font-weight: 500;
}
