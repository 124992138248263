p {
    color: #626262;
}

.card {
    height: 16rem;
    width: inherit;
    padding: 2rem 1rem 0 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: calc(var(--font-size) * 1.2);
    background-color: var(--creme);
}

.card_title {
    font-size: calc(var(--font-size) * 1.4);
}

.top_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
}

.bottom_section {
    height: 60%;
}

@media only screen and (min-width: 760px) {
    .card {
        padding: 2rem 2rem 1rem 2rem;
        font-size: var(--font-size);
    }

    .card_title {
        font-size: calc(var(--font-size) * 1.4);
    }
}
