.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--creme);
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
}

.text {
    text-align: center;
}

.iconContainer {
    position: absolute;
    top: -10px;
    fill: red;
}

.icon {
    width: 2.5rem;
    fill: var(--salmon);
}
