.outer_container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 90%;
    min-height: 100vh;
    max-width: 1140px;
    margin: 0 auto;
    padding: 2rem 1rem 6rem 1rem;
    text-align: left;
    z-index: -1;
}

.inner_container {
    z-index: 1;
}

.side_splatter {
    width: 10%;
}

.side_splatter_1 {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
}

.side_splatter_2 {
    position: absolute;
    top: 70%;
    right: 0;
    transform: rotate(180deg);
    z-index: 0;
}

.side_splatter_3 {
    position: absolute;
    top: 25%;
    right: 0;
    transform: rotate(180deg);
    z-index: 0;
}

.title {
    font-size: xx-large;
}

.section_title {
    font-size: x-large;
}

.popular_section {
    background-color: var(--salmon);
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

.popular_section_content {
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 1rem 0 5rem 0;
}

.popular_section_content h3 {
    color: white;
}

.trending_section_content {
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
    justify-items: center;
}

.trending_section_content_padding {
    padding: 0px;
}

.top_categories_subsection {
    padding-bottom: 2rem;
}

.category_subtitle {
    font-size: calc(var(--font-size) * 1.2);
    text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
    .trending_section_content_padding {
        padding: '0 3rem';
    }
}

@media only screen and (min-width: 760px) {
    .trending_section_content {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
    }

    .side_splatter {
        width: 5%;
    }
}

@media only screen and (min-width: 1200px) {
    .trending_section_content {
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
    }

    .side_splatter {
        width: 5%;
    }
}

/* Following adds space between cards in carousel */
.slick-slide > div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}
