:root {
    --fontSize: 1.4rem;
}

.grow {
    flex-grow: 1;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 80em;
    margin-bottom: 2em;
    border-radius: 10px;
}

.card_link {
    text-decoration: none;
    color: inherit;
}

.card_media {
    background-color: var(--darkPurple);
    height: 14em;
    width: 100%;
}

.card_content {
    flex: 1;
    text-align: left;
    width: 90%;
}

.card_content_center {
    flex: 2;
    margin: auto;
}

.card_content_loading {
    color: var(--darkPurple);
}

.project_name {
    margin: 0 1rem 1rem 1rem;
    font-size: calc(var(--fontSize) + 0.8rem);
}

.section_details {
    display: flex;
    align-items: flex-start;
}

.section_details_left {
    display: flex;
    flex-direction: column;
}

.section_author {
    display: flex;
    align-items: center;
}

.author_avatar {
    height: 2.8rem !important;
    width: 2.8rem !important;
    margin-right: 1rem;
}

.author_name {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
    font-weight: 600;
}

.author_inner_content {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 0.5rem;
}

.author_inner_content img {
    height: 2rem;
    width: 2rem;
}

.section_details_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.card_actions {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    margin-bottom: 1rem;
}

.action_button {
    padding: 0.2rem;
    margin-left: 1rem;
}

.action_button_icon {
    width: 2rem;
    height: 2rem;
}

.section_category {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    overflow: hidden;
}

.section_category_more {
    white-space: nowrap;
    font-weight: bold;
    font-size: var(--fontSize);
    text-decoration: none;
    color: darkgrey;
}

.description {
    font-size: 1.4rem;
    padding-top: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* number of lines to show */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

@media only screen and (min-width: 600px) {
    .card {
        flex-direction: row;
        align-items: flex-start;
        height: 18em;
        width: 90%;
    }

    .card_media {
        width: 18em;
        height: 18em;
    }

    .card_actions {
        margin-bottom: 1.5rem;
    }

    .project_name {
        font-size: 2rem;
    }

    .author_avatar {
        height: 3rem;
        width: 3rem;
    }

    .author_name {
        font-size: 1.6rem;
    }

    .description {
        -webkit-line-clamp: 3;
    }
}

@media only screen and (min-width: 760px) {
    .card {
        height: 18em;
    }

    .card_media {
        width: 18em;
        height: 18em;
    }

    .description {
        -webkit-line-clamp: 3;
    }
}