.card {
    border-radius: 10px;
    background-color: white;
    height: 30rem;
    width: 120%;
    max-width: 30rem;
    padding: 0 0 0.5rem 0;
}

.card_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.featured_image_container {
    height: 70%;
    border-radius: 10px 10px 0 0;
    background-color: var(--darkPurple);
}

.section_details {
    padding: 1rem;
}

.section_details h1 {
    line-height: 1;
}

.details_row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.description {
    margin: 1rem 0;
    text-align: left;
    font-size: 1.4rem;
    padding-top: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* number of lines to show */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.section_author {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 30%;
    border-radius: 0 0 10px 10px;
    background-color: var(--salmon);
}

.author_avatar {
    height: 5rem;
    width: 5rem;
}

.author_name {
    color: white;
    font-size: calc(var(--fontSize) * 1.2);
}

.author_inner_content {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 0.5rem;
}

.author_inner_content img {
    height: 2rem;
    width: 2rem;
}

.card_link {
    text-decoration: none;
    color: inherit;
}

.project_name_like_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .card {
        height: 33rem;
        width: 33rem;
    }
}

@media only screen and (min-width: 760px) {
    .card {
        height: 35rem;
        width: 35rem;
    }
}
