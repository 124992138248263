.root {
    padding: 0 3rem;
    text-align: left;
}

.title {
    font-size: 4rem;
}

.description {
    font-size: 1.4rem;
}