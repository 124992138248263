.top_container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.container {
    display: flex;
    row-gap: 2rem;
    flex-direction: column;
    padding: 0rem 2rem 2rem 2rem;
}

.close_icon {
    font-size: 2rem;
    margin: 0;
}

.title {
    display: flex;
    align-items: center;
}

.back_button {
    padding: 0;
}

.back_button:hover {
    background-color: transparent;
}

.back_icon {
    font-size: 2rem;
    padding: 1rem 0;
    margin-right: 1rem;
}

.title_text {
    margin: 0;
}
