:root {
    --fontSize: 1.4rem;
}

/*
* TextField border-color when focused.
* https://material-ui.com/components/text-fields/#color
*/
:root .MuiOutlinedInput-root:focus-within fieldset {
    border-color: var(--darkPurple);
}

.container {
    display: grid;
    row-gap: 3rem;
    max-width: 94rem;
    margin: 1rem auto;
    padding: 1rem;
}

.form {
    display: grid;
    row-gap: 3rem;
}

.section {
    display: flex;
    flex-direction: column;
}

.subtitle {
    text-transform: capitalize;
    font-size: calc(var(--fontSize) + 0.8rem);
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
}

.section_images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-gap: 1.5rem;
}

.remove_button_wrap {
    width: fit-content;
    position: relative;
}

.remove_button {
    height: 1rem;
    width: 1rem;
    padding: 0;
    z-index: 2;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: white;
}

.remove_button:hover {
    background-color: white;
}

.remove_image_icon {
    fill: red;
    font-size: 2rem;
}

.dialog_image {
    width: 100%;
}

.dialog_textfield {
    font-size: 3rem !important;
}

@media only screen and (min-width: 600px) {
    .section_images {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
}

@media only screen and (min-width: 760px) {}
