.body {
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
    font-size: medium;
}

.modified {
    font-size: 1.4rem;
    color: var(--lightPurple);
}
