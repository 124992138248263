.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.container {
    background-color: white;
    border: 1px solid #000;
    border-radius: 0.8rem;
    padding: 1.5rem;
    width: 35rem;
    font-size: 1rem;
}

.button {
    display: inline;
    margin-right: 1rem;
    float: left;
}

.text {
    font-size: calc(var(--font-size));
}

@media only screen and (min-width: 600px) {
    .modal {
        width: 80rem;
    }

    .container {
        padding: 3rem;
    }
}
