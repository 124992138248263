.container {
    margin: auto;
    min-height: 15rem;
    max-height: 100rem;
    padding: 0 2rem 0 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    aspect-ratio: 16/9;
}

.slide_content {
    width: 100%;
    height: 100%;
    background-color: darkgray;
}
