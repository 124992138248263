.container {
    color: white;
    padding: 0.3rem 1rem;
    border-radius: 3px;
    height: 2rem;
    width: fit-content;
    text-transform: capitalize;
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
}
