.container {
    min-width: 20rem;
    width: 100%;
}

.menu {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    list-style: none;
    padding: 0;
}

.menu_item {
    border-width: 1px 0;
    border-style: solid;
    border-color: lightgrey;
}

.alert {
    font-size: 1.36rem;
}
/* TODO - Consolidate reused styles. This is used in basicInfoPanel */
.buttonRow {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}

.spacer {
    flex-grow: 1;
}

.modalInnerContainer {
    background-color: white;
    padding: 1.5rem;
    width: 35rem;
    font-size: 1rem;
}

.modalTitle {
    margin-bottom: 4rem;
}

.modalButton {
    display: inline;
    margin-right: 1rem;
    float: left;
}

@media only screen and (min-width: 760px) {
    .container {
        min-width: 30rem;
    }
}
