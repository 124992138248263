.outer_container {
    background-color: white;
    min-height: 100vh;
    z-index: -1;
}

.inner_container {
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 3rem 1rem 5rem 1rem;
}

.corner_splatter {
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 0;
}

.section_title {
    font-weight: normal;
    font-size: calc(var(--font-size) * 2.4);
    margin: 0;
}

.section_subtitle {
    font-size: var(--font-size);
    font-weight: 500;
    color: var(--salmon);
    margin: 2rem 0 0.6rem 0;
}

.section_blurb {
    font-size: calc(var(--font-size) * 1.4);
    color: #626262;
}

@media only screen and (min-width: 600px) {
    .section_subtitle {
        margin: 5rem 0 1rem 0;
    }
}

@media only screen and (min-width: 760px) {
    .corner_splatter {
        width: 25%;
    }

    .section_title {
        font-size: calc(var(--font-size) * 3);
    }

    .section_subtitle {
        font-size: calc(var(--font-size) * 1.2);
    }

    .section_blurb {
        font-size: 1.8rem;
        color: #626262;
    }
}
