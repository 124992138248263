.section_outer_container {
    min-height: 30rem;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    padding: 5rem 0;
}

.section_inner_container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    min-height: 30rem;
}

.section_left_panel {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 90vw;
    text-align: left;
}

.section_right_panel {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-grow: 1;
}

.section_title {
    font-weight: normal;
    font-size: calc(var(--font-size) * 1.6);
    margin: 0;
}

.section_subtitle {
    font-size: calc(var(--font-size));
    font-weight: 500;
    color: var(--salmon);
    margin: 2rem 0 0.6rem 0;
}

.section_blurb {
    display: flex;
    flex-wrap: wrap;
    font-size: calc(var(--font-size));
    color: #626262;
    line-height: 1.5;
}

.section_hero {
    width: 60%;
    max-width: 30rem;
    z-index: 1;
    align-self: center;
    border-radius: 50%;
}

@media only screen and (min-width: 600px) {
    .section_title {
        font-weight: normal;
        font-size: calc(var(--font-size) * 2.4);
        margin: 0;
    }
    
    .section_subtitle {
        margin: 5rem 0 1rem 0;
    }

    .section_blurb {
        font-size: calc(var(--font-size) * 1.2);
        color: #626262;
        line-height: 1.5;
    }
}

@media only screen and (min-width: 760px) {
    .section_title {
        font-size: calc(var(--font-size) * 3);
    }

    .section_subtitle {
        font-size: calc(var(--font-size) * 1.2);
    }

    .section_blurb {
        font-size: 1.6rem;
        color: #626262;
    }

    .section_container {
        justify-content: space-between;
    }

    .section_left_panel {
        width: inherit;
    }

    .section_right_panel {
        width: 26rem;
        justify-content: center;
    }

    .section_hero {
        width: 100%;
    }
}
