:root {
    --fontSize: 1.4rem;
}

.grow {
    flex: 1;
}

.wrapper {
    width: 100%;
}

.outer_container {
    width: 90%;
    max-width: 94rem;
    margin: 1rem auto;
}

.inner_container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: repeat(10, auto);
    row-gap: 1rem;
    padding: 1rem 0 3rem 0;
}

.row_1 {
    grid-row: 1;
}

.row_2 {
    grid-row: 2;
}

.row_3 {
    grid-row: 3;
}

.row_4 {
    grid-row: 4;
}

.row_5 {
    grid-row: 5;
}

.row_6 {
    grid-row: 6;
}

.row_7 {
    grid-row: 7;
}

.row_8 {
    grid-row: 8;
}

.row_9 {
    grid-row: 9;
}

.row_10 {
    grid-row: 10;
}

.row_11 {
    grid-row: 11;
}

.row_12 {
    grid-row: 12;
}

.row_13 {
    grid-row: 13;
}

.column_span_4 {
    grid-column: span 4;
}

.column_1_3 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.column_1_4 {
    grid-column-start: 1;
    grid-column-end: 4;
}

.column_3_5 {
    grid-column-start: 3;
    grid-column-end: 5;
}

.column_4_5 {
    grid-column-start: 4;
    grid-column-end: 5;
}

.featured_image {
    height: 18rem;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-color: var(--teal);
}

.project_name {
    margin: 0;
    font-size: calc(var(--fontSize) + 1.4rem);
    text-align: start;
}

.section_action_tag {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 !important;
    margin-bottom: 1rem;
}

.section_actions {
    display: flex;
    flex-wrap: nowrap;
}

.action_button {
    padding: 0.2rem;
    margin: 0 1rem;
    max-height: 2.5rem;
}

.action_button_icon {
    width: 2rem;
    height: 2rem;
}

.categories_wrapper {
    grid-column-start: 3;
    grid-column-end: 5;
}

.section_category {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: right;
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.8rem;
}

.section_author {
    display: flex;
    align-items: center;
}

.author_avatar {
    height: 3.2rem;
    width: 3.2rem;
    margin-right: 1rem;
}

.author_name {
    font-size: calc(var(--fontSize) + 0.2rem);
    font-weight: 600;
}

.description {
    font-size: calc(var(--fontSize) + 0.2rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: justify;
}

.subtitle {
    font-size: calc(var(--fontSize) + 1rem);
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-align: left;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-content: center;
    grid-gap: 1rem;
}

@media only screen and (min-width: 600px) {
    .project_name {
        font-size: calc(var(--fontSize) + 2rem);
    }

    .details_actions {
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .author_avatar {
        height: 3rem;
        width: 3rem;
    }

    .author_name {
        font-size: calc(var(--fontSize) + 0.6rem);
    }

    .cards {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-gap: 1.5rem;
    }
}

@media only screen and (min-width: 760px) {
    .featured_image {
        height: 24rem;
    }

    .section_action_tag {
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .project_name {
        font-size: calc(var(--fontSize) + 2rem);
    }
}
