.container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.left_panel {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    text-align: left;
}

.right_panel {
    display: flex;
    width: 90%;
}

.hero {
    width: 100%;
    z-index: 1;
}

.start_button {
    text-decoration: none;
    width: 95%;
    height: 6rem;
}

@media only screen and (min-width: 760px) {
    .container {
        justify-content: space-between;
    }

    .left_panel {
        width: inherit;
    }

    .right_panel {
        width: 26rem;
    }

    .start_button {
        text-decoration: none;
        width: 15rem;
        height: 6rem;
    }
}
