/* .appbar {
    background-color: #FDACA7;
    background-color: transparent;
    box-shadow: none;
} */

.logo {
    margin-top: 0.3rem;
    width: 5rem;
    height: 5rem;
}

.grow {
    flex-grow: 1;
}

.no_style {
    text-decoration: none;
}

.menu_button {
    margin-right: 16px;
    z-index: 1;
}

.sign_in_button {
    font-weight: bold;
    color: whitesmoke;
    font-size: 1.5rem;
}

.sign_in_link {
    text-decoration: none;
    color: inherit;
    z-index: 1;
}

.sign_up_button {
    font-weight: bold;
    color: whitesmoke;
    font-size: 1.5rem;
    border-color: whitesmoke;
    margin-left: 0.5rem;
    z-index: 1;
}

.menu_button_icon {
    width: 3rem;
    height: 3rem;
    z-index: 1;
}

.primary_button {
    background-color: #d0abe1;
    color: whitesmoke;
}

.primary_button:hover {
    background-color: #ae65cf;
}

.title {
    display: none;
    font-size: 2.4rem;
    font-weight: bold;
}

.header_link {
    display: none;
    margin-right: 1.6em;
    margin-left: 0;
    margin-bottom: 0.25rem;
    font-size: 1.6rem;
    font-weight: bold;
    z-index: 1;
}

.search {
    font-size: 1.8rem;
    position: relative;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-right: 16px;
    margin-left: 0;
    width: 100%;
    z-index: 1;
}

.search_icon {
    padding: 0 0 0 1rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    color: grey;
    align-items: center;
    justify-content: center;
}

.MuiInputBase-input {
    font-size: 1.2rem;
}

.input_input {
    padding: 0.19em 0 0 4em;
    width: 100%;
}

.section_desktop {
    display: none;
}

.section_mobile {
    display: flex;
}

.MuiPopover-paper {
    width: 18rem;
}

.MuiListItem-root {
    height: 4rem;
    font-size: 1.4rem !important;
}

.premiumIndicator {
    height: 16px;
    margin-left: 0.5rem;
}

@media only screen and (min-width: 600px) {
    .title {
        display: block;
    }

    .search {
        margin-left: 24px;
        width: auto;
    }

    .spacer {
        width: 4rem;
    }
}

@media only screen and (min-width: 760px) {
    .input_input {
        width: 16rem;
    }

    .header_link {
        display: block;
    }

    .section_desktop {
        display: flex;
        align-items: center;
    }

    .section_mobile {
        display: none;
    }

    .premiumIndicator {
        height: 26px;
    }
}
