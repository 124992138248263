.cards {
    display: grid;
    width: 100%;
    grid-gap: 2rem;
    align-items: center;
    justify-items: center;
}

.title {
    font-size: 2.6rem;
    margin: 0;
}

@media only screen and (min-width: 600px) {
    .title {
        font-size: 2.8rem;
    }
}

@media only screen and (min-width: 760px) {
    .cards {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        width: inherit;
    }
}
