.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--lightGrey);
    border-radius: 5px;
    height: 15rem;
    font-size: var(--fontSize);
}

.section_title {
    text-transform: capitalize;
    font-weight: bold;
}
