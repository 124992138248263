:root {
    --fontSize: 1.2rem;
}

.container {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 5px;
    height: 2.5rem;
}

.divider {
    border-left: 1px solid lightgrey;
}

.button {
    padding: .2rem .5rem;
    min-width: fit-content;
}

.icon {
    width: 2rem;
    height: 2rem;
    color: red;
}

.counter {
    font-size: calc(var(--fontSize) + 0.2rem);
    align-self: center;
    padding: 0 1rem;
    font-weight: 500;
}
