.project_name {
    color: var(--salmon);
    font-weight: bold;
}

.result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--creme);
    padding: 1rem;
    border-radius: 5px;
}

.button_container {
    display: flex;
    gap: 1rem;
}
