.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 4rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: var(--creme);
}

.title_container {
    max-width: 90rem;
}

.title {
    font-size: 1.4rem;
    font-weight: 500;
}

.form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1140px;
    margin-bottom: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
}

.form_text {
    font-size: 2.2rem;
    max-width: 80rem;
}

.textfield {
    background-color: white;
    border-radius: 5px;
    width: 100%;
}

.gdpr {
    color: #626262;
    font-size: 0.8rem;
}

@media only screen and (min-width: 760px) {
    .title {
        font-size: 1.8rem;
    }

    .form {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
    }
}
