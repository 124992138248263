.outer_container {
    background-color: white;
    min-height: 100vh;
    z-index: -1;
}

.inner_container {
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 1rem 1rem 5rem 1rem;
    z-index: 1;
}

.page_title {
    font-size: 3rem;
    text-align: left;
}

.content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

@media only screen and (min-width: 600px) {
    .page_title {
        font-size: 4rem;
    }
}

@media only screen and (min-width: 760px) {
    .page_title {
        font-size: 4rem;
    }
}
