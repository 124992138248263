@import "./App.css";
@import "./styles/navbar.module.css";
@import "./styles/home.module.css";
@import "./styles/contact.module.css";
@import "./styles/showcase.module.css";
@import "./styles/profile.module.css";
@import "./styles/project.module.css";
@import "./styles/projectCard.module.css";
@import "./styles/componentCard.module.css";
@import "./styles/categoryTag.module.css";
@import "./styles/likeButton.module.css";
@import "./styles/tabBar.module.css";
@import "./styles/editProject.module.css";
@import "./styles/notFound.module.css";
@import "./styles/basicInfoPanel.module.css";

:root {
    --salmon: #fdaca7;
    --teal: #98eccc;
    --darkPurple: #3c376b;
    --lightPurple: #d0abe1;
    --lightGrey: #e9e9e9;
    --creme: #ffede1;
    --lightBlack: #0A100D;
    --font-size: 1.4rem;

    --footer-container-height: 55rem;

}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: whitesmoke;


}

body .cookie-notice {
        border: 2px solid var(--salmon);
        background-color: white;
        border-radius: 1rem;
        padding: 1rem;
        position: fixed;
        right: 0rem;
        bottom: 0rem;
        z-index: 100;
    }

body .cookie-notice span {
            font-size: 1.5rem;
        }

body .cookie-notice .cn-buttons {
            float: right;
            display: inline;
        }

body .cookie-notice .cn-buttons .cm-btn {
                background-color: var(--lightPurple);
                border: 0;
                border-radius: 1rem;
                padding: 1rem 2rem;
                color: white;
            }

body .cookie-notice .cn-buttons .cm-btn:hover {
                cursor: pointer;
                background-color: var(--darkPurple);
                border: 0;
                border-radius: 1rem;
                padding: 1rem 2rem;
                color: white;
            }

body .cookie-notice .cn-learn-more {
            margin-right: 0.2rem;
            float: left;
            display: inline;
            color: var(--lightPurple);
            padding: 1rem 2rem;
            text-decoration: none;
        }

body .cookie-modal {
        border: 2px solid var(--salmon);
        background-color: white;
        border-radius: 1rem;
        padding: 1rem 4rem;
        position: fixed;
        right: 0rem;
        bottom: 0rem;
        z-index: 100;
    }

body .cookie-modal .hide {
            float: right;
            margin-bottom: 1rem;
            background: none;
            border: none;
        }

body .cookie-modal .hide:after {
            content: 'x';
            font-size: 2rem;
        }

body .cookie-modal .cm-caret {
            color: white;
            display: none;
        }

body .cookie-modal .cm-btn-accept {
            float: right;
            background-color: var(--lightPurple);
            border: 0;
            border-radius: 1rem;
            padding: 1rem 2rem;
            color: white;
        }

body .cookie-modal .cm-btn-accept:hover {
            cursor: pointer;
            background-color: var(--darkPurple);
            border: 0;
            border-radius: 1rem;
            padding: 1rem 2rem;
            color: white;
        }

body .cookie-modal .cm-powered-by {
            display: none;
        }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@media only screen and (min-width: 600px) {

        body .cookie-notice {
            width: 40rem;
            padding: 1rem 4rem;
            right: 4rem;
            bottom: 4rem;
        }

        body .cookie-modal {
            width: 40rem;
            padding: 1rem 4rem;
            right: 4rem;
            bottom: 4rem;
        }

}


@media (min-width: 767px) {
    :root {
        --footer-container-height: 14rem;
    }
}
