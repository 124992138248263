.alert {
    font-size: 1.39rem;
}

.form_container {
    background-color: white;
    padding: 4rem 2rem;
    border: 1px solid black;
    border-radius: 0.5rem;
    width: 90%;
}

.form_header {
    color: #FDACA7;
    font-size: 3rem;
}

.form_info {
    margin: 2rem 0 2rem 0;
    font-size: 1.5rem;
}

.send_button {
    background-color: #D0ABE1;
    color: whitesmoke;
    font-size: 1rem;
}

@media only screen and (min-width: 760px) {
    .form_container {
        width: 70rem;
        margin-top: 4rem;
    }

}
