.root {
    font-size: 1.38rem;
    border-color: var(--salmon);
    border-width: 0.2rem;
    margin: auto;
}

/* TODO - Remove important */
.icon {
    color: var(--lightPurple) !important;
}
