.container {
    background-color: var(--salmon);
    width: 100%;
    border-radius: 5px;
    padding: 0;
    color: white;
    font-size: var(--fontSize);
}

.menu_list {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0.5rem;
    margin: 0;
    justify-content: space-evenly;
}

.menu_item {
    padding: 1rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 2rem;
}

.menu_item:hover {
    cursor: pointer;
}

.menu_item span {
    text-transform: capitalize;
    padding-right: 0.5rem;
    display: none;
}

@media only screen and (min-width: 760px) {
    /* .container {
        width: 25%;
    }

    .menu_list {
        flex-direction: column;
        justify-content: flex-start;
        padding: 1rem;
    }

    .menu_item {
        padding: 1rem 0;
    } */

    .menu_item span {
        display: block;
    }
}