
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.top_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.title {
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--lightPurple);
}

.close_icon {
    font-size: 2rem;
    margin: 0;
}

.container {
    background-color: white;
    border-radius: 0.8rem;
    padding: 4rem;
    width: 85rem;
    height: 80%;
    overflow-y: scroll;
}

.button {
    display: inline;
}
