.largeCenterTitle {
    font-weight: bold;
    text-align: center;
    font-size: 4rem;
}

.mediumCenterTitle {
    font-weight: bold;
    text-align: center;
    font-size: 2.0rem;
}

.smallCenterDescription {
    text-align: center;
    font-size: 1.6rem;
}
